import React from 'react';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel as CarouselVendor } from 'react-responsive-carousel';

import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { Image, ImageProps } from '../atoms/image';

export type SliderProps = {
  images?: {
    firstSet: ImageProps[];
    secondSet: ImageProps[];
  };
  children: React.ReactElement[];
  sliderWidthRatio?: number;
  sliderHeightRatio?: number;
  showDots?: boolean;
  showDotsOnContent?: boolean;
  currentSlide?: number;
  dragEnabled?: boolean;
  className?: string;
  onActiveSlideChange?: (slide: number) => void;
};

const Dot = styled.div<{ active: boolean }>`
  display: inline-block;
  height: 10px;
  width: 10px;

  padding: 0;
  margin: 10px;

  border-radius: 50%;

  background: ${({ theme, active }) =>
    active ? theme.color.palette['base-blue'] : theme.color.palette['gray-darken-1']};
`;

const Wrapper = styled.div``;

const ImageColumn = styled.div`
  flex: 1;

  &:hover {
    animation-play-state: paused;
  }

  animation: animation-x-axis 30s linear infinite;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
  grid-gap: 20px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    animation: animation-y-axis 30s linear infinite;
    grid-template-columns: 1fr;
    grid-gap: 60px;
  }
`;

const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  height: 100%;
  width: 100%;
  align-self: center;

  .carousel__slide--hidden {
    opacity: 0.001;
    transition: opacity 200ms ease-in;
  }

  .control-dots {
    bottom: 0;
  }
`;
const AnimatedDiv = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    max-height: 494px;
    flex-direction: row;
  }

  @keyframes animation-y-axis {
    0% {
      transform: translateY(50%);
    }
    50% {
      transform: translateY(-50%);
    }
    100% {
      transform: translateY(50%);
    }
  }

  @keyframes animation-x-axis {
    0% {
      transform: translateX(50%);
    }
    50% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(50%);
    }
  }

  .control-dots {
    bottom: 0;
  }

  .carousel {
    height: 100%;
    display: flex;
  }
`;

const Carousel: React.FC<SliderProps> = (props: SliderProps) => {
  return (
    <CarouselWrapper className={props.className}>
      <CarouselVendor
        autoPlay={true}
        infiniteLoop={true}
        dynamicHeight={true}
        emulateTouch={true}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        selectedItem={props.currentSlide}
        onChange={props.onActiveSlideChange}
        showIndicators={props.showDots ?? true}
        renderIndicator={(onClickHandler, isSelected) => (
          <Dot active={isSelected} onClick={onClickHandler} onKeyDown={onClickHandler} />
        )}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
      >
        {props.children.map((child, index) => (
          <div key={index}>{child}</div>
        ))}
      </CarouselVendor>
    </CarouselWrapper>
  );
};

export const Slider: React.FC<SliderProps> = props => {
  if (props.images) {
    return (
      <AnimatedDiv>
        <ImageColumn>
          {props.images?.firstSet?.map(img => (
            <Image key={img.id || img.alt || img.title} {...img} />
          ))}
        </ImageColumn>

        <CarouselVendor
          css={{ flex: '1' }}
          autoPlay={true}
          infiniteLoop={true}
          dynamicHeight={false}
          emulateTouch={true}
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          showIndicators={props.showDots ?? true}
          renderIndicator={(onClickHandler, isSelected) => (
            <Dot active={isSelected} onClick={onClickHandler} onKeyDown={onClickHandler} />
          )}
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
          onChange={props.onActiveSlideChange}
          selectedItem={props.currentSlide}
        >
          {props.children.map((child, index) => (
            <div key={index}>{child}</div>
          ))}
        </CarouselVendor>
        <ImageColumn>
          {props.images?.secondSet?.map(img => (
            <Image key={img.id || img.alt || img.title} {...img} />
          ))}
        </ImageColumn>
      </AnimatedDiv>
    );
  }
  return (
    <Wrapper>
      <Carousel {...props} />
    </Wrapper>
  );
};

Slider.defaultProps = {
  children: [],
  showDots: true,
  showDotsOnContent: true,
  dragEnabled: true,
};

export const sliderFragments = graphql`
  fragment ImageSlider on cms_ComponentHomepageImageSlider {
    __typename
    slides {
      cta_title
      cta_text
      cta_link
      content
      background_image {
        ...CommonMediaField
        staticFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
      background_image_mobile {
        ...CommonMediaField
        staticFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
  }

  fragment CitationSlider on cms_ComponentHomepageCitationSlider {
    __typename
    slides {
      content
    }
    media_second_set {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(width: 352, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
    media_first_set {
      ...CommonMediaField
      staticFile {
        childImageSharp {
          gatsbyImageData(width: 352, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  }
`;
