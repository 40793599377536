import React from 'react';

import { CTA, CtaProps } from './cta';
import styled from '@emotion/styled';
import { AlignedMarkdown } from '../atoms/aligned-markdown';
import { Image, ImageProps } from '../atoms/image';

export type SlideProps = {
  mdContent?: string;
  cta?: Pick<CtaProps, 'text' | 'link' | 'size'>;
  marginBottom?: number;
  background?: ImageProps;
};

const SlideWrapper = styled.div<Pick<SlideProps, 'marginBottom'>>`
  height: 100%;
  align-items: center;

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    font-weight: 400;
    line-height: 42px;
  }

  blockquote {
    max-width: 635px;
    margin-bottom: ${({ marginBottom }) => marginBottom}px;
  }

  p {
    line-height: 26px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
`;

const OverImageWrapper = styled(Wrapper)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

const SlideContent: React.FC<SlideProps> = props => {
  return (
    <OverImageWrapper>
      {props.mdContent && <AlignedMarkdown align="center" markdown={props.mdContent} />}
      {props.cta && (
        <CTA text={props.cta.text} link={props.cta.link} size={props.cta.size || 'medium'} icon="right-arrow" />
      )}
    </OverImageWrapper>
  );
};
const QuoteContent: React.FC<SlideProps> = props => {
  return (
    <Wrapper>
      {props.mdContent && <AlignedMarkdown align="center" markdown={props.mdContent} />}
      {props.cta && (
        <CTA text={props.cta.text} link={props.cta.link} size={props.cta.size || 'medium'} icon="right-arrow" />
      )}
    </Wrapper>
  );
};

export const Slide: React.FC<SlideProps> = props => {
  return (
    <SlideWrapper marginBottom={props.marginBottom}>
      {props.background ? (
        <div style={{ position: 'relative' }}>
          <Image {...props.background} />
          <SlideContent {...props} />
        </div>
      ) : (
        <QuoteContent {...props} />
      )}
    </SlideWrapper>
  );
};

Slide.defaultProps = {
  marginBottom: 20,
};
